<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 "
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card class="mx-auto mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row>
              <v-col cols="10" class="py-0" align="left">
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col cols="2" align="right" class="py-0">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="loadProcesos()"
              >
                <!-- Filtros comunes -->
                <v-row>
                  <!-- Fecha creacion desde -->
                  <v-col cols="3" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaDesdeSelected"
                          label="Creación desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaDesde = parseDateToIso(fechaDesdeSelected)
                          "
                          outlined
                          clearable
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaDesde"
                        no-title
                        @change="fechaDesdeSelected = formatDate(fechaDesde)"
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha creacion hasta -->
                  <v-col cols="3" sm="6" md="3" class="py-0">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaHastaSelected"
                          label="Creación hasta"
                          :append-icon="calendarIcon"
                          v-mask="'##/##/####'"
                          hint="Formato DD/MM/AAAA"
                          @blur="
                            fechaHasta = parseDateToIso(fechaHastaSelected)
                          "
                          outlined
                          autocomplete="not"
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            fechaDesdeSelected || fechaHastaSelected
                              ? rules.validDate.concat(
                                  new Date(
                                    parseDateToIso(fechaDesdeSelected)
                                  ) <=
                                    new Date(
                                      parseDateToIso(fechaHastaSelected)
                                    ) || 'Formato incorrecto'
                                )
                              : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaHasta"
                        no-title
                        @change="fechaHastaSelected = formatDate(fechaHasta)"
                        @input="menu2 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <!-- Fecha Vigencia desde  -->
                  <v-col cols="3" sm="6" md="3" class="py-0">
                    <v-menu
                      ref="menu3"
                      v-model="menu3"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVigDesdeSelected"
                          label="Inicio de Vigencia desde"
                          :append-icon="calendarIcon"
                          v-bind="attrs"
                          autocomplete="not"
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          @blur="
                            fechaVigDesde = parseDateToIso(
                              fechaVigDesdeSelected
                            )
                          "
                          outlined
                          clearable
                          dense
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVigDesde"
                        no-title
                        @change="
                          fechaVigDesdeSelected = formatDate(fechaVigDesde)
                        "
                        @input="menu3 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <!-- Fecha vigencia hasta -->
                  <v-col cols="3" sm="6" md="3" class="py-0">
                    <v-menu
                      v-model="menu4"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fechaVigHastaSelected"
                          label="Inicio de vigencia hasta"
                          :append-icon="calendarIcon"
                          hint="Formato DD/MM/AAAA"
                          v-mask="'##/##/####'"
                          clearable
                          @blur="
                            fechaVigHasta = parseDateToIso(
                              fechaVigHastaSelected
                            )
                          "
                          outlined
                          autocomplete="not"
                          dense
                          v-bind="attrs"
                          v-on="on"
                          :rules="
                            fechaVigDesdeSelected || fechaVigHastaSelected
                              ? rules.validDate.concat(
                                  new Date(
                                    parseDateToIso(fechaVigDesdeSelected)
                                  ) <=
                                    new Date(
                                      parseDateToIso(fechaVigHastaSelected)
                                    ) || 'Formato incorrecto'
                                )
                              : []
                          "
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fechaVigHasta"
                        no-title
                        @change="
                          fechaVigHastaSelected = formatDate(fechaVigHasta)
                        "
                        @input="menu4 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row class="pr-4 pl-4" justify="end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="mr-2"
                        v-bind="attrs"
                        v-on="on"
                        size="28"
                        @click="resetForm"
                      >
                        {{ clearFiltersIcon }}
                      </v-icon>
                    </template>
                    <span>Limpiar filtros</span>
                  </v-tooltip>
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    elevation="2"
                    small
                    type="submit"
                    form="filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsActualizacProcesosFacturacion"
            :loading="isLoading"
            class="elevation-1"
            :search="search"
            item-key="procAumTablaFacId"
            show-expand
            single-expand
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="8">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" align="end" v-if="canCreate">
                    <v-btn
                      color="primary"
                      class="to-right"
                      @click="nuevaActTablaFact()"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canVerDetalleFac">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="verDetalleProceso(item.procAumTablaFacId)"
                  >
                    {{ seeIcon }}
                  </v-icon>
                </template>
                <span>Ver detalle</span>
              </v-tooltip>
              <v-tooltip left v-if="canAnular && item.usuAnula == null">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="openDialogAnulaProceso(item)"
                  >
                    {{ anularIcon }}
                  </v-icon>
                </template>
                <span>Anular proceso</span>
              </v-tooltip>
            </template>

            <!-- Menu desplegable -->
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fecAlta }}
                <strong style="padding-left: 80px">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left: 80px">Fecha anulación:</strong>
                {{ item.fecAnula }}
                <strong style="padding-left: 80px">Usuario anulación:</strong>
                {{ item.usuAnula }}
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" :md="showHelp ? 8 : 11" align="end">
        <v-btn
          color="primary"
          outlined
          class="fontsize p-2"
          :disabled="
            itemsActualizacProcesosFacturacion
              ? itemsActualizacProcesosFacturacion.length == 0
              : ''
          "
          @click="exportLista"
        >
          Exportar lista completa
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog
      v-model="modalVerDetalleActualizacion"
      v-if="modalVerDetalleActualizacion"
      @keydown.esc="closeModalVerDetalleActualizacion"
      max-width="90%"
      persistent
    >
      <VerDetalleActualizacionTablaFact
        @closeModalVerDetalleActualizacion="closeModalVerDetalleActualizacion"
        :procAumTablaFacId="procAumTablaFacId"
      ></VerDetalleActualizacionTablaFact>
    </v-dialog>

    <v-dialog
      v-if="modalVerTablasAnular"
      v-model="modalVerTablasAnular"
      @keydown.esc="closeAndReload"
      max-width="40%"
      persistent
    >
      <VerTablasAnular
        :idToAnular="idToAnular"
        :tablasModificadas="tablasModificadas"
        @closeAndReload="closeAndReload"
        @loadProcesos="loadProcesos"
      ></VerTablasAnular>
    </v-dialog>
    <v-dialog
      v-if="modalVerTablasVigenciaPost"
      v-model="modalVerTablasVigenciaPost"
      @keydown.esc="closeAndReload"
      max-width="60%"
      persistent
    >
      <VerTablaVigenciaPosterior
        :tablasVigPost="tablasVigPost"
        @closeAndReload="closeAndReload"
      ></VerTablaVigenciaPosterior>
    </v-dialog>
    <!-- <v-dialog
      v-model="modalNuevoProcesoImportacion"
      v-if="modalNuevoProcesoImportacion"
      @keydown.esc="toggleModalNuevoProcesoImportacion"
      max-width="70%"
      persistent
    >
      <ImportarAportesEsperados
        @toggleModalNuevoProcesoImportacion="toggleModalNuevoProcesoImportacion"
      ></ImportarAportesEsperados>
    </v-dialog> -->
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'35%'"
      :confirmButtonText="'Anular'"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="anularProcesoActualizacionFac()"
    />
    <ConfirmDialog
      :text="textConfirmDialog"
      :openConfirm.sync="openConfirmDialog"
    />
  </v-container>
</template>

<script>
import GoBackBtn from "@/components/shared/GoBackBtn";
import DeleteDialog from "@/components/shared/DeleteDialog";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { mapActions } from "vuex";
import VerDetalleActualizacionTablaFact from "@/components/modules/prestadores/VerDetalleActualizacionTablaFact.vue";
import VerTablasAnular from "@/components/modules/prestadores/VerTablasAnular.vue";
import VerTablaVigenciaPosterior from "@/components/modules/prestadores/VerTablaVigenciaPosterior.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";
import Ayuda from "@/components/shared/Ayuda.vue";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import axios from "@/axios/axios-api";

export default {
  name: "ActualizacionTablasFacturacion",
  directives: { mask },
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    VerDetalleActualizacionTablaFact,
    VerTablasAnular,
    VerTablaVigenciaPosterior,
    FiltersSelected,
    Ayuda,
    ConfirmDialog
  },

  data() {
    return {
      title: enums.titles.ACTUALIZACION_TABLAS_FACTURACION,
      optionCode: enums.webSiteOptions.CONSULTA_TABLA_FAC,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      calendarIcon: enums.icons.CALENDAR,
      textConfirmDialog: "",
      openConfirmDialog: false,
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      fechaHastaSelected: null,
      fechaDesdeSelected: null,
      fechaVigDesdeSelected: null,
      fechaVigHastaSelected: null,
      fechaDesde: null,
      fechaHasta: null,
      fechaVigDesde: null,
      fechaVigHasta: null,
      expanded: [],
      isFormValid: true,
      rules: rules,
      search: "",
      routeToGo: "ConsultaTablaFacturacion",
      titleDelete: "¿Desea anular el proceso?",
      modalVerTablasAnular: false,
      tablasModificadas: [],
      tablasVigPost: [],
      modalVerTablasVigenciaPost: false,
      searchIcon: enums.icons.SEARCH,
      checkIcon: enums.icons.CHECK_OUTLINE,
      anularIcon: enums.icons.CIRCLE_NONE,
      seeIcon: enums.icons.SEE,
      expanded: [],
      showFilters: true,
      filtersApplied: [],
      itemsActualizacProcesosFacturacion: [],
      headers: [
        {
          text: "N° de proceso",
          align: "start",
          value: "procAumTablaFacId",
          sortable: false
        },

        {
          text: "Nueva vigencia",
          value: "nuevaVigencia",
          sortable: false
        },
        {
          text: "Porcentaje de importe fijo por nomenclador",
          align: "start",
          value: "porcImporteFijo",
          sortable: false
        },
        {
          text: "Porcentaje de unidades",
          align: "end",
          value: "porcUnidades",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
        {
          text: "",
          value: "data-table-expand",
          sortable: false,
          align: "end"
        }
      ],
      isLoading: false,
      canCreate: false,
      canAnular: false,
      canAnularNuevo: false,
      showExpand: false,
      showHelp: false,
      showIcon: true,
      canVerDetalleFac: false,
      procAumTablaFacId: null,
      modalVerDetalleActualizacion: false,
      showDeleteModal: false,
      modalNuevoProcesoImportacion: false,
      idToAnular: null,
      confirmValidaTablasModificadas: true,
      aporteEsperadoAnuladoConErrores: []
    };
  },

  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    if (this.$route.params.fromCreate) {
      this.loadProcesos();
    }
  },
  mounted() {
    this.setAllowedActions();
  },
  methods: {
    ...mapActions({
      getConsultaProcesos: "prestadores/getConsultaProcesos",
      anularProcesoActualizFactura: "prestadores/anularProcesoActualizFactura",
      getOptionsByOptionCode: "AdministracionSistema/getOptionsByOptionCode",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions
            .NUEVO_PROCESO_TABLA_FACTURACION:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions
            .REVERTIR_PROCESO_TABLA_FACTURACION:
            this.canAnular = true;
            break;
          case enums.modules.adminSistema.permissions
            .VER_DETALLE_PROCESO_TABLA_FACTURACION:
            this.canVerDetalleFac = true;
            break;
          default:
            break;
        }
      });
    },
    async setAllowedActions() {
      const optionCode = "867AF0DF-D73A-46D7-86D2-152D1557F141";
      const resp = await this.getOptionsByOptionCode(optionCode);
      const actions = localStorage.getItem("allowedActions");
      if (actions) {
        localStorage.removeItem("allowedActions");
      }
      localStorage.setItem("allowedActions", JSON.stringify(resp?.data.children));
      if (resp.status === 200) this.setPermisos();
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    resetForm() {
      this.$refs["filters-form"].reset();
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    exportLista() {
      let result = [];
      this.itemsActualizacProcesosFacturacion?.forEach(x =>
        result.push({
          ["N° de proceso"]: x.procAumTablaFacId,
          ["Nueva vigencia"]: x.nuevaVigencia,
          ["Porcentaje de importe fijo"]: x.porcImporteFijo,
          ["Porcentaje de unidades"]: x.porcUnidades,
          ["Fecha de alta"]: x.fecAlta,
          ["Usuario alta"]: x.usuAlta,
          ["Fecha de anulación"]: x.fecAnula,
          ["Usuario anulación"]: x.usuAnula
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(
        formato,
        "Listado de actualización de tablas de facturación"
      );
    },
    nuevaActTablaFact() {
      this.$router.push({
        name: "NuevaActualizacionTablaFacturacion"
      });
    },
    async loadProcesos() {
      (this.showFilters = false), (this.isLoading = true);
      this.customizeFiltersApplied();
      const data = {
        creacionDesde: this.parseDateToIso(this.fechaDesdeSelected),
        creacionHasta: this.parseDateToIso(this.fechaHastaSelected),
        vigenciaDesde: this.parseDateToIso(this.fechaVigDesdeSelected),
        vigenciaHasta: this.parseDateToIso(this.fechaVigHastaSelected)
      };
      const res = await this.getConsultaProcesos(data);
      this.itemsActualizacProcesosFacturacion = res;
      this.isLoading = false;
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.fechaDesdeSelected) {
        this.filtersApplied.splice(0, 0, {
          key: "fechaDesde",
          label: "Creacion desde ",
          model: this.fechaDesdeSelected
        });
      }
      if (this.fechaHastaSelected) {
        this.filtersApplied.splice(1, 0, {
          key: "fechaHasta",
          label: "Creación hasta",
          model: this.fechaHastaSelected
        });
      }
      if (this.fechaVigDesdeSelected) {
        this.filtersApplied.splice(1, 0, {
          key: "fechaVigDesde",
          label: "Vigencia desde",
          model: this.fechaVigDesdeSelected
        });
      }
      if (this.fechaVigHastaSelected) {
        this.filtersApplied.splice(1, 0, {
          key: "fechaVigHasta",
          label: "Vigencia hasta",
          model: this.fechaVigHastaSelected
        });
      }
    },
    verDetalleProceso(procAumTablaFacId) {
      this.modalVerDetalleActualizacion = true;
      this.procAumTablaFacId = procAumTablaFacId;
    },
    closeModalVerDetalleActualizacion() {
      this.modalVerDetalleActualizacion = false;
    },
    closeAndReload() {
      this.modalVerTablasAnular = false;
      this.modalVerTablasVigenciaPost = false;
    },

    toggleModalNuevoProcesoImportacion() {
      this.modalNuevoProcesoImportacion = !this.modalNuevoProcesoImportacion;
      this.loadProcesos();
    },

    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },

    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    openDialogAnulaProceso(item) {
      this.idToAnular = item.procAumTablaFacId;
      this.showDeleteModal = true;
    },
    async anularProcesoActualizacionFac(item) {
      const response = await this.anularProcesoActualizFactura({
        procAumTablaFacId: this.idToAnular,
        validaTablasModificadas: true
      });
      if (response.error == 0) {
        this.setAlert({
          type: "success",
          message: response.mensaje
        });
        this.loadProcesos();
      } else if (response.error == 1) {
        this.tablasModificadas = response.tablasModificadas;
        this.modalVerTablasAnular = true;
        this.setAlert({
          type: "warning",
          message: response.mensaje
        });
      } else {
        this.tablasVigPost = response.tablasVigenciaPosterior;
        this.modalVerTablasVigenciaPost = true;
        this.setAlert({
          type: "warning",
          message: response.mensaje
        });
      }
      this.showDeleteModal = false;
    }

    // exportActualizacionesAnuladasConErrores() {
    //   let result = [];
    //   this.aporteEsperadoAnuladoConErrores?.forEach(x =>
    //     result.push({
    //       ["N° de grupo familiar"]: x.grupo,
    //       ["Tipo y N° de doc."]: x.tipoDoc + "-" + x.docId,
    //       ["Apellido y nombre"]: x.apellidoNombre,
    //       ["Importe"]: x.importe,
    //       ["Período desde"]: x.periodoDesde,
    //       ["Período hasta"]: x.periodoHasta,
    //       ["Importe"]: x.importe,
    //       ["Observaciones"]: x.observaciones
    //     })
    //   );
    //   let formato = {
    //     filas: result,
    //     hoja: "Resultado lista"
    //   };
    //   helpersExcel.excelExport(
    //     formato,
    //     "Detalle aportes esperados con errores"
    //   );
    // }
  }
};
</script>

<style lang="scss" scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield;
}
.fontsize {
  font-size: 12px;
}
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.no-upper-case {
  text-transform: none;
}
</style>
